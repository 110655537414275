@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600&family=Montserrat:wght@700&display=swap");
@tailwind base;
@tailwind components;

@layer components {
  aside.sidebar ul li a + ul {
    display: none;
  }

  aside.sidebar ul li a:not(.active) {
    @apply text-white bg-slate-500 bg-opacity-5;
  }

  aside.sidebar ul li a.active {
    @apply text-white bg-white bg-opacity-5;
  }

  aside.sidebar ul li a.active + ul {
    @apply flex bg-white bg-opacity-5 pl-3;
  }

  .stretched-link {
    @apply absolute inset-0 z-10;
  }

  .logo .color-1 {
    @apply fill-blue-700;
  }

  .logo .color-2 {
    @apply fill-yellow-500;
  }

  .logo.yellow .color-1 {
    @apply fill-yellow-500;
  }

  .logo.yellow .color-2 {
    @apply fill-yellow-500;
  }
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  appearance: none;
}

@tailwind utilities;
